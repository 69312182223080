:root {
    --font-head-family: "TypoPRO Bebas Neue";
    --font-head-size: 32pt;
    --font-text-family: "TypoPRO Source Sans Pro";
    --font-text-size: 14pt;
    --color-black: #333333;
    --color-white: #ffffff;
    --color-blue-dark: #2a639e;
    --color-blue: #3070b3;
    --color-blue-shade-1: #598dc2;
    --color-green: #a0af00;
    --color-grey-dark: #737373;
    --color-grey: #b3b3b3;
    --color-grey-shade-1: #cdcdcd;
    --color-grey-shade-2: #e6e6e6;
    --color-grey-shade-3: #f7f7f7;
    --color-warning: #e37222;
    --spacing-sm: 5px;
    --spacing: 10px;
    --spacing-md: 15px;
    --spacing-lg: 20px;
    --border: 1px;
    --icon: 38px;
    --icon-mini: 25px;
    --icon-small: 30px;
    --listItemHeight: 48px;
    --barHeight: 40px;
    --btnHeight: 20px;
    --btnHeight-mini: 16px;
    --buttonBar-sm: 42px;
    --headline: 42px;
}

html,
body {
    margin: 0;
    padding: 0;
    width: 100%;
    height: 100%;
    user-select: none;
    cursor: default;
    -webkit-overflow-scrolling: touch;
    overflow: hidden;
    font-family: var(--font-text-family);
}

h2 {
    margin: var(--spacing) 0 var(--spacing-md) 0;
    min-height: 31px;
}

.campsRow,
.campsCol {
    height: 100%;
}

.campsHeadline {
    font-weight: 700;
    font-size: 18px;
    margin-bottom: 10px;
}

.campsContent {
    height: calc(100% - 2px);
    margin-left: var(--spacing);
    border: 1px solid var(--color-grey-shade-2);
}

.campsNoSelection {
    margin: var(--spacing);
}

.campsFormContent {
    height: 100%;
    position: relative;
}

.campsDialogHeader {
    position: absolute;
    height: var(--headline);
    width: calc(100% - (2 * var(--spacing)));
    margin: var(--spacing) var(--spacing) 0 var(--spacing);
    display: flex;
    justify-content: space-between;
    font-weight: bold;
    font-size: 24px;

    .campsReleaseBtn {
        height: 32px;
    }
}

.campsScrollContainerButtonBar {
    position: absolute;
    top: calc(var(--headline) + var(--spacing));
    overflow-x: hidden;
    overflow-y: auto;
    height: calc(100% - var(--buttonBar-sm) - var(--headline) - var(--spacing));
    width: 100%;
}

.campsScrollContainer {
    overflow-x: hidden;
    overflow-y: auto;
}

.campsInnerForm {
    margin: var(--spacing);
}

.campsButtonBar {
    position: absolute;
    right: 0;
    bottom: 0;
    width: calc(100% - var(--spacing));
    height: auto;
    padding: var(--spacing-sm) var(--spacing) var(--spacing-sm) 0;
    background: var(--color-grey-shade-2);
    display: flex;
    justify-content: flex-end;
    gap: var(--spacing);
}

.campsBottomSpace {
    margin-bottom: var(--spacing-sm);
}

.campsSelect,
.campsFormItem,
.el-date-editor.el-input.campsFormItem {
    width: 100%;
}

.campsMaxHeightContainer {
    height: 100%;
}

.campsIconBtnCont {
    height: var(--icon);
    width: var(--icon);
    border-radius: 4px;
    border: var(--border) solid var(--color-grey);
    color: var(--color-black);
    background: var(--color-white);
    display: flex;
    align-items: center;
    justify-content: center;
    cursor: pointer;
    .campsIconBtn {
        font-size: var(--btnHeight);
    }
}

.campsIconBtnCont.campsMini {
    height: var(--icon-mini);
    width: var(--icon-mini);
    .campsIconBtn {
        font-size: var(--btnHeight-mini);
    }
}

.campsIconBtnCont.campsSmall {
    height: var(--icon-small);
    width: var(--icon-small);
}

.campsIconBtnCont:hover {
    color: var(--color-blue);
    border-color: var(--color-blue);
}

.campsIconBtnCont.campsIconDisabled {
    color: var(--color-grey);
    border-color: var(--color-grey);
    cursor: not-allowed;
}

.campsDisabledColor {
    color: var(--color-grey);
}

.campsStatesTabsContainer {
    overflow: hidden;
    .campsStateTabs {
        width: 100%;
        height: var(--barHeight);
        .campsStateTab {
            width: 50%;
            .el-radio-button__inner {
                width: 100%;
            }
        }
    }
    .campsStateTabsContent {
        height: calc(100% - var(--barHeight));
    }
}


.el-form--label-top .el-form-item__label {
    text-overflow: ellipsis;
    overflow: hidden;
    width: 100%;
    white-space: nowrap;
    padding: 0;
}

.el-menu-item,
.el-submenu__title {
    border-left: 1px solid var(--color-grey-shade-3);
    border-right: 1px solid var(--color-grey-shade-3);
    border-bottom: 1px solid var(--color-grey-shade-3);
    background-color: var(--color-grey-shade-2);
}

.el-menu-item:first-child {
    border-top: 1px solid var(--color-grey-shade-3);
}

.el-menu-item:hover,
.el-submenu__title:hover {
    background-color: var(--color-grey-shade-3);
}

.el-submenu .el-menu-item {
    background-color: var(--color-grey-shade-3);
}

.el-submenu .el-menu-item:hover {
    background-color: var(--color-grey-shade-2);
}

.el-menu-item.is-active,
.el-table__body tr.current-row > td {
    background-color: var(--color-blue);
    color: var(--color-white);
}

.el-menu-item.is-active:hover {
    background-color: var(--color-blue-dark);
    color: var(--color-white);
}

.el-select-dropdown__empty {
    padding: 16px 10px;
    text-align: left;
}

.el-input.is-disabled .el-input__inner {
    background-color: var(--color-grey-shade-3);
}

.el-table--enable-row-hover .el-table__body tr:hover > td {
    background-color: var(--color-grey-shade-3);
}

.el-table--enable-row-hover .el-table__body tr.current-row:hover > td {
    background-color: var(--color-blue-dark);
    color: var(--color-white);
}

.campsTableHeader th {
    background: var(--color-grey-shade-3);
    color: var(--color-grey-dark);
}

.campsTableInput {
    width: 100%;
}

.el-table td.campsCenterCol {
    text-align: center;
    text-align: -webkit-center;
}

.el-table td.campsStateCol .cell {
    overflow: inherit;
    color: var(--color-warning);
}
