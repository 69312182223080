.campsCourse {
    width: 100%;

    .campsCourseTypeBar {
        display: flex;
        justify-content: flex-start;
        height: calc(var(--icon-small) + (2 * var(--border)));
        margin-bottom: var(--spacing-sm);
        .campsCourseTypeSelect {
            width: 300px;
        }
    }

    .campsTypeCol {
        font-weight: bold;
    }

    .campsEdited,
    .campsEdited input {
        font-style: italic;
    }
}
